import React from 'react'

import * as Literals from './Literals'
import * as styles from './styles.module.scss'

/**
 */
export const Title = () => (
  <span className={styles.Title}>
    <Literals.Title />
  </span>
)
