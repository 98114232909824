import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Description = translated('app.project')(
  'TagManage.Export.Description',
)

/**
 */
export const Button = translated('app.project')('TagManage.Export.Button')
