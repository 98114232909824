import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

export const DescriptionSection = ({ children }) => (
  <div className={styles.DescriptionSection}>{children}</div>
)

export const ButtonSection = ({ children }) => (
  <div className={styles.ButtonSection}>{children}</div>
)
