import React from 'react'

import { Trans } from 'react-i18next'

import * as Literals from './Literals'
import * as Styled from './Styled'

/**
 */
export const Description = ({
  components: { CautionTextDecorator = Styled.CautionText } = {},
}) => {
  const { t, descriptionI18nkey } = Literals.useLiterals()
  return (
    <Trans
      t={t}
      i18nKey={descriptionI18nkey}
      components={{ Caution: <CautionTextDecorator /> }}
    />
  )
}

/**
 */
export const Button = ({
  components: { ButtonDecorator = Styled.DefaultButton } = {},
  onClick,
  onUpload = () => {},
}) => {
  const ref = React.useRef()

  return (
    <>
      <input
        type="file"
        name="file"
        accept=".csv"
        ref={ref}
        style={{ visibility: 'hidden', display: 'none' }}
        onChange={(e) => {
          if (e.target) {
            console.log(e.target)
            onUpload(e.target.files[0])
            e.target.value = ''
          }
        }}
      />
      <ButtonDecorator
        onClick={(e) => {
          ref.current.click()
        }}
      >
        <Literals.Button />
      </ButtonDecorator>
    </>
  )
}
