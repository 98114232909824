import React from 'react'

import * as Namespaces from '@arch-log/webapp.modules/project.product.tag/components/Namespaces'
import * as Buttons from '@arch-log/webapp.shared/Buttons'
import * as Literals from './Literals'
import * as styles from './styles.module.scss'

/**
 */
export const Default = () => (
  <Namespaces.Actions.Initializer>
    <Form />
  </Namespaces.Actions.Initializer>
)

const Form = () => {
  const [namespace, setNamespace] = React.useState('')
  const { addEntry } = React.useContext(Namespaces.Actions.Context)
  const { formatPlaceholder } = Literals.useLiterals()

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation()
        e.preventDefault()

        if (namespace && namespace.length > 0) {
          addEntry(namespace)
          setNamespace('')
        }
        return false
      }}
    >
      <input
        type="text"
        placeholder={formatPlaceholder('EnterTagGroup')}
        onChange={(e) => setNamespace(e.target.value)}
        value={namespace}
        className={styles.Input}
      />
    </form>
  )
}
