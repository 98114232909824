import React from 'react'

import { useTranslation } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const useLiterals = () => {
  const { t } = useTranslation('app.project')

  const formatPlaceholder = (key, ...options) =>
    t(`TagManage.${key}`, ...options)

  return {
    t,
    formatPlaceholder,
  }
}
