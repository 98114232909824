import React from 'react'

import {
  translated,
  useTranslation,
} from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Description = translated('app.project')(
  'TagManage.Import.Description',
)

/**
 */
export const Button = translated('app.project')('TagManage.Import.Button')

/**
 */
export const ConfirmMessage = translated('app.project')(
  'TagManage.Import.ConfirmMessage',
)

/**
 */
export const ConfirmOk = translated('app.project')('TagManage.Import.ConfirmOk')

/**
 */
export const ConfirmCancel = translated('app.project')(
  'TagManage.Import.ConfirmCancel',
)

/**
 */
export const useLiterals = () => {
  const { t } = useTranslation('app.project')

  const descriptionI18nkey = t(`TagManage.Import.Description`)

  return {
    t,
    descriptionI18nkey,
  }
}
