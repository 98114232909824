import React from 'react'

import { Context } from './Context'
import { useDispatch } from 'react-redux'

import * as actions from '@arch-log/webapp.modules/project.product.tag/actions'

/**
 */
export const Initializer = ({ children }) => {
  const dispatch = useDispatch()

  const importFile = (...args) => {
    dispatch(actions.importFile(...args))
  }

  const exportFile = (...args) => dispatch(actions.exportFile(...args))

  return (
    <Context.Provider value={{ importFile, exportFile }}>
      {children}
    </Context.Provider>
  )
}
