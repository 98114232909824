import React from 'react'

import * as ProjectAuthorization from '@arch-log/webapp.modules/project.authorization.v2/components'
import * as Permissions from '@arch-log/webapp.modules/project.authorization.v2/permissions'
import * as PageHeadTemplates from '@arch-log/webapp.shared/Page/Head'
import * as NoPermission from '@arch-log/webapp.shared/Errors/NoPermission'
import * as Partial from 'partials/TagManagePage'

/**
 */
const Page = () => {
  const hasPermission = ProjectAuthorization.Remote.Queries.MyEntry.useHasPermissions()

  if (
    !(
      hasPermission(Permissions.TagsImport) ||
      hasPermission(Permissions.TagsExport)
    )
  ) {
    return <NoPermission.Default />
  }

  return <Partial.Default />
}

export default Page

/**
 */
export const Head = ({ pageContext }) => {
  return <PageHeadTemplates.Language language={pageContext?.language} />
}
