import React from 'react'

import * as styles from './layout.module.scss'

/**
 */
export const Container = ({ children }) => (
  <div className={styles.ContainerWrapper}>
    <div className={styles.Container}>{children}</div>
  </div>
)

/**
 */
export const Header = ({ children }) => (
  <div className={styles.Header}>{children}</div>
)

/**
 */
export const Body = ({ children }) => (
  <div className={styles.Body}>{children}</div>
)

/**
 */
export const Footer = ({ children }) => (
  <div className={styles.Footer}>{children}</div>
)
