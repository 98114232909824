import React from 'react'

import axios from 'axios'

import * as DefaultComponents from './DefaultComponents'
import * as Layout from './Layout'

import * as BaseExport from './Export'
import * as BaseImport from './Import'
import * as BaseNamespaceList from './NamespaceList'
import * as ModuleActions from '@arch-log/webapp.modules/project.product.tag/components/Actions'
import { useConfirmModal } from '@arch-log/webapp.shared/ConfirmModal'

export const Import = ({
  components: {
    Button = BaseImport.Components.Button,
    Description = BaseImport.Components.Description,
    Layout: {
      Container = BaseImport.Layout.Container,
      DescriptionSection = BaseImport.Layout.DescriptionSection,
      ButtonSection = BaseImport.Layout.ButtonSection,
    } = {},
  } = {},
}) => {
  const { importFile } = React.useContext(ModuleActions.Context)
  const { showConfirm } = useConfirmModal()

  return (
    <Container>
      <DescriptionSection>
        <Description />
      </DescriptionSection>
      <ButtonSection>
        <Button
          onUpload={(file, e) => {
            showConfirm({
              message: <BaseImport.Literals.ConfirmMessage />,
              ok: <BaseImport.Literals.ConfirmOk />,
              cancel: <BaseImport.Literals.ConfirmCancel />,
              onOk: () => {
                importFile(file)
              },
            })
          }}
        />
      </ButtonSection>
    </Container>
  )
}

export const Export = ({
  components: {
    Button = BaseExport.Components.Button,
    Description = BaseExport.Components.Description,
    Layout: {
      Container = BaseExport.Layout.Container,
      DescriptionSection = BaseExport.Layout.DescriptionSection,
      ButtonSection = BaseExport.Layout.ButtonSection,
    } = {},
  } = {},
}) => {
  const { exportFile } = React.useContext(ModuleActions.Context)

  return (
    <Container>
      <DescriptionSection>
        <Description />
      </DescriptionSection>
      <ButtonSection>
        <Button onExport={() => exportFile()} />
      </ButtonSection>
    </Container>
  )
}

export const NamespaceList = () => <BaseNamespaceList.Default />

/**
 */
export const Default = ({
  components: {
    Title = DefaultComponents.Title,
    Layout: {
      Container = Layout.Container,
      Header = Layout.Header,
      Body = Layout.Body,
      Footer = Layout.Footer,
    } = {},
  } = {},
}) => (
  <ModuleActions.Initializer>
    <Container>
      <Header>
        <Title />
      </Header>
      <Body>
        <NamespaceList />
      </Body>
      <Footer>
        <Import />
        <Export />
      </Footer>
    </Container>
  </ModuleActions.Initializer>
)
