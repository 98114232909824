import React from 'react'

import * as Namespaces from '@arch-log/webapp.modules/project.product.tag/components/Namespaces'
import * as Namespace from '@arch-log/webapp.modules/project.product.tag/components/Namespaces/Reference'
import * as BaseTag from '@arch-log/webapp.atomics/components/Tag'

import * as BaseForm from './Form'

import * as Layout from './Layout'
import * as Literals from './Literals'

const Tag = ({
  components: {
    Label = BaseTag.Label,
    Delete = BaseTag.Delete,
    Layout: { Container = BaseTag.Container } = {},
  } = {},
}) => {
  const { removeEntry } = React.useContext(Namespaces.Actions.Context)
  const {
    namespace: { name = null },
  } = React.useContext(Namespace.Context)

  return (
    <Container>
      <Label>
        <Namespace.Properties.Name />
      </Label>
      <Delete
        onDelete={() => {
          removeEntry(name)
        }}
      />
    </Container>
  )
}

const Form = BaseForm.Default

/**
 */
export const Default = ({
  components: {
    Layout: {
      Container = Layout.Container,
      Header = Layout.Header,
      Body = Layout.Body,
      Footer = Layout.Footer,
    } = {},
  } = {},
}) => (
  <Container>
    <Header>
      <Literals.Title />
    </Header>
    <Body>
      <Namespaces.Actions.Initializer>
        <Namespaces.Loaders.AtOnce>
          <Namespaces.Each>
            <Tag />
          </Namespaces.Each>
        </Namespaces.Loaders.AtOnce>
      </Namespaces.Actions.Initializer>
    </Body>
    <Footer>
      <Form />
    </Footer>
  </Container>
)
