import React from 'react'

import * as Literals from './Literals'
import * as Styled from './Styled'

/**
 */

export const Description = Literals.Description

/**
 */

export const Button = ({
  components: { Decorator = Styled.DefaultButton } = {},
  onExport = () => {},
}) => (
  <Decorator onClick={(e) => onExport()}>
    <Literals.Button />
  </Decorator>
)
