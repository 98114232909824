import React from 'react'

import * as Buttons from '@arch-log/webapp.shared/Buttons'
import * as styles from '../styles.module.scss'

/**
 */
export const DefaultButton = ({ children, ...props }) => (
  <Buttons.DefaultButton className={styles.DefaultButton} {...props}>
    {children}
  </Buttons.DefaultButton>
)
